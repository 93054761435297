import React, { useState, useEffect } from 'react'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import ProgressBar from 'src/components/ProgressBar'

import { Advanced, Return, Content } from '../../style'

import { Button } from './style'

interface IQuestion {
  pergunta: string;
  resposta: string;
}

type questionsProps = {
  questions: {
    pergunta: string;
    resposta: string;
  }[];
  questionData: string;
  setQuestions: Function;
  setStep: Function;
  nextStep: string;
  backStep: string;
  step: string;
  progress: number;
  setProgress: Function;
  progressValue: number;
}

const Rating = ({ questions, setQuestions, questionData, setStep, nextStep, backStep, step, progress, setProgress, progressValue }: questionsProps) => {
  const [ question, setQuestion ] = useState<IQuestion>({
    pergunta: questionData,
    resposta: '',
  })

  const handleClick = () => {
    setQuestions([ ...questions, question ])
    setQuestion({ pergunta: questionData, resposta: '' })
    setStep(nextStep)
  }

  useEffect(() => {
    setProgress(progress)
  }, [ step ])

  return (
    <Content>
      <div>
        <h2 className='fs-16 lh-20 text-white fw-600 mb-2'>
          {questionData}
        </h2>
        <ProgressBar progress={progressValue} styles='text-white mb-4' />
        <div className='d-flex align-items-center justify-content-center mb-5'>
          <Button onClick={() => setQuestion({ ...question, resposta: '1' })}>
            <OrangeDsIcon
              icon={parseInt(question.resposta) >= 1 ? 'star-fill' : 'star'}
              size='LG'
              color='#ff7a00'
            />
          </Button>
          <Button onClick={() => setQuestion({ ...question, resposta: '2' })}>
            <OrangeDsIcon
              icon={parseInt(question.resposta) >= 2 ? 'star-fill' : 'star'}
              size='LG'
              color='#ff7a00'
            />
          </Button>
          <Button onClick={() => setQuestion({ ...question, resposta: '3' })}>
            <OrangeDsIcon
              icon={parseInt(question.resposta) >= 3 ? 'star-fill' : 'star'}
              size='LG'
              color='#ff7a00'
            />
          </Button>
          <Button onClick={() => setQuestion({ ...question, resposta: '4' })}>
            <OrangeDsIcon
              icon={parseInt(question.resposta) >= 4 ? 'star-fill' : 'star'}
              size='LG'
              color='#ff7a00'
            />
          </Button>
          <Button onClick={() => setQuestion({ ...question, resposta: '5' })}>
            <OrangeDsIcon
              icon={parseInt(question.resposta) >= 5 ? 'star-fill' : 'star'}
              size='LG'
              color='#ff7a00'
            />
          </Button>
        </div>
      </div>
      <div className='row d-flex align-items-center buttons'>
        <div className='col-6'>
          <Return onClick={() => setStep(backStep)}>
            Voltar
          </Return>
        </div>
        <div className='col-6'>
          <Advanced disabled={question.resposta === ''} onClick={() => handleClick()}>
            Avançar
          </Advanced>
        </div>
      </div>
    </Content>
  )
}

export default Rating
