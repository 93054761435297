import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      salaVipInterViagens: imageSharp(fluid: {originalName: { regex: "/salaVipInterViagens/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      salaVipGlobalAccount: imageSharp(fluid: {originalName: { regex: "/salaVipGlobalAccount/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
