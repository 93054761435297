import React, { useState, MouseEvent, useEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import SectionComponent from 'src/components/SectionComponent'
import SalaVipForm from '../../components/UI/Forms/SalaVip'
import Icon from 'src/components/UI/MarkdownIcon'
import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useWidth from 'src/hooks/window/useWidth'

import Message from './_message/_index'

import { Container, Section } from './style'

import pageContext from './pageContext.json'

const WIDTH_MD = 768

type ModalProps = {
  closeModal?: () => void;
}

type StorageProps = {
  id: string;
  acesso: number;
}

const AcessoSalaVip = ({ closeModal }: ModalProps) => {
  const windowWidth = useWidth()
  const [ isForm, setIsForm ] = useState(false)
  const [ modal, setIsModal ] = useState(false)
  const [ acesso, setAcesso ] = useState(1)
  const [ hasAccess, setHasAccess ] = useState(false)

  const openModal = () => {
    setIsModal(true)
  }

  const handleClick = () => {
    setIsForm(true)
    setIsModal(false)
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsForm(false)
    setIsModal(false)
  }

  useEffect(() => {
    const location = window.location.href
    const encript = location.split('?')[1]
    if (encript && !encript.includes('acesso=')) {
      const param = window.atob(encript)

      const params = param.split('&')

      const acesso = params[0].split('=')[1]
      const id = params[1].split('=')[1]

      if (acesso) {
        setAcesso(Number(acesso))
      }

      const storage = window.localStorage.getItem('accessControl')
      if (storage) {
        const data = window.atob(storage)
        const dataDecript = JSON.parse(data) as StorageProps[]

        const access = dataDecript.find((item: StorageProps) => item.acesso === Number(acesso) && item.id === id)

        if (access) {
          setHasAccess(true)
        }
      }
    }
  }, [])

  return (
    <Layout pageContext={pageContext}>
      {
        hasAccess ? (
          <Section className='py-5'>
            <div className='container'>
              <div className='mb-4 pb-2 pl-0'>
                <Icon color='#ff7a00' width={windowWidth < WIDTH_MD ? 62 : 92} height={windowWidth < WIDTH_MD ? 22 : 52} icon='banco-color' directory='logos' />
              </div>
              <Message />
            </div>
          </Section>
        ) : (
          <SectionComponent
            id='form-sala-vip'
            SectionStyles='py-5 bg-grayscale--500'
            minHeight={{ sm: '100vh' }}
            RowStyles='h-100'
          >
            { modal && (
              <Container isModal={closeModal} className='d-flex align-items-center modal-vip'>
                <div className='col-12 d-flex justify-content-center flex-column'>
                  <h3 className='text-grayscale--500 fs-12 lh-15 fs-md-18 lh-md-22 fw-600'>
                    A entrada na Sala VIP ou Estabelecimento Comercial depende estritamente do cliente possuir:
                  </h3>
                  <ul className='text-grayscale--500'>
                    <li>um meio de acesso válido; </li>
                    <li>um cartão de embarque válido;</li>
                    <li>qualquer identificação adicional que a Sala VIP ou o Estabelecimento Comercial possa exigir dos clientes e de seus convidados, que pode incluir passaporte, carteira de identidade ou carteira de habilitação. </li>
                  </ul>
                  <p className='text-grayscale--500 fs-10 lh-12 fs-md-12 lh-md-15'>
                    Algumas Salas VIP e Estabelecimentos Comerciais na Europa estão localizados dentro do espaço Schengen
                    no aeroporto, o que significa que o acesso está disponível apenas para Clientes que estejam viajando
                    entre países Schengen.
                  </p>
                  <p className='text-grayscale--500 fs-10 lh-15 fs-md-12 lh-md-15 pt-3'>
                    Para mais informações, confira as <a href='https://airport.mastercard.com/pt/conditions-of-use' title='Confira as condições de uso' target='_blank' rel='noreferrer' className='fw-600'>Condições de Uso</a>.
                  </p>
                  <button
                    onClick={handleReturn} title='Entendi'
                    className='bt btn--lg fs-14 lh-17 px-5 text-white bg-orange--extra text-none border-0 rounded-3 mt-2 w-100'
                  >
                    Entendi
                  </button>
                </div>
              </Container>
            )}
            {!modal &&
              <>
                <Container className='col-12 col-lg-6'>
                  <div className='mb-4 pb-2 pl-0'>
                    <Icon color='#ff7a00' width={windowWidth < WIDTH_MD ? 62 : 92} height={windowWidth < WIDTH_MD ? 22 : 52} icon='banco-color' directory='logos' />
                  </div>
                  {!isForm
                ? (
                  <div className='px-0'>
                    <div className='d-flex align-items-end my-5 px-0'>
                      <div className='col-10 pl-0'>
                        <h1 className='fs-32 lh-40 text-white font-sora mb-0 fw-600'>
                          <span className='d-block'>Boas-vindas</span> à Sala VIP
                        </h1>
                      </div>
                      <div className='col-2 mb-2 cursor-pointer' onClick={openModal}>
                        <OrangeDS size='LG' color='#ff7a00' icon='info' />
                      </div>
                    </div>
                    {
                      acesso <= 4 ? (
                        <p className='fs-16 lh-19 text-white fw-400'>
                          Clientes do cartão Inter Mastercard Black contam com acesso gratuito às Salas VIP durante suas viagens. Para continuar aproveitando o benefício, pedimos que responda ao formulário abaixo e envie uma foto do seu cartão de embarque. <strong>Assim, podemos oferecer a você sempre a melhor experiência</strong>.
                        </p>
                      ) : (
                        <p className='fs-16 lh-19 text-white fw-400'>
                          Clientes do cartão Inter Mastercard Black contam com acesso gratuito às Salas VIP durante suas viagens. Para continuar aproveitando o benefício,  anexe uma foto do seu cartão de embarque. <strong>Assim, podemos oferecer a você sempre a melhor experiência</strong>.
                        </p>
                      )
                    }
                    <p className='fs-16 lh-19 text-white mb-4 fw-400'>
                      Seguindo as condições de uso da Mastercard e LoungeKey, o não envio das informações e apresentação do cartão de embarque está sujeito à cobrança de $35,00 no seu próximo acesso a uma Sala VIP. Fique tranquilo(a), seus dados estarão seguros com a gente!
                    </p>
                    <button
                      onClick={handleClick}
                      className='bt btn--lg fs-14 lh-17 px-5 text-white bg-orange--extra text-none border-0 rounded-3 mt-5 w-100 fw-600'
                    >
                      {
                        acesso <= 4 ? 'Responder formulário' : 'Anexar'
                      }

                    </button>
                  </div>
                )
                : <SalaVipForm />
              }
                </Container>
              </>
            }
          </SectionComponent>
        )
      }

    </Layout>
  )
}

export default AcessoSalaVip
