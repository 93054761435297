import React from 'react'
import Canceled from '@interco/icons/build-v4/orangeds/MD/canceled'

// styles
import { Section, Circle } from './style'

const Message = () => {
  return (
    <Section>
      <Circle>
        <Canceled height={32} width={32} color='#ffffff' />
      </Circle>
      <h2 className='fs-24 lh-30 text-white fw-600 text-center mb-5'>Este formulário já foi respondido!</h2>
      <p className='fs-16 lh-19 text-white fw-400 text-center mb-4'>
        Em cada acesso, um <strong>novo formulário</strong> será enviado para o seu e-mail cadastrado. Ele é único e deve ser preenchido individualmente pelo titular.
      </p>
      <p className='fs-16 lh-19 text-white fw-600 text-center'>
        Para acessar, confira o seu e-mail e responda o novo formulário, combinado?
      </p>
    </Section>
  )
}

export default Message
