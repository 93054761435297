import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 0 8px 24px 8px;
`
export const Circle = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #00AA4F;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`
