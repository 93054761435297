import styled from 'styled-components'

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96px;
`
export const Circle = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #F56A50;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`
