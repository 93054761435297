import React from 'react'
import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import CheckCircle from '@interco/icons/build-v4/orangeds/MD/check-circle'

import { Container, Circle } from './style'

type SuccessProps = {
  FormSuccess?: string;
}

const Success = ({ FormSuccess }: SuccessProps) => {
  return (
    <>
      {FormSuccess === 'form1' &&
        (
          <Container className='d-flex align-items-center px-0'>
            <div className='col-12 px-0'>
              <h2 className='fs-24 lh-30 text-white fw-600 mb-4 font-sora'>
                <span className='d-block'>Agradecemos pelo</span> envio!
              </h2>
              <p className='fs-16 lh-19 text-white'>
                A partir de agora, sempre que você acessar uma sala VIP através do seu cartão Inter Black, vamos solicitar
                que anexe o seu cartão de embarque e nos envie esse documento.
              </p>
              <p className='fs-16 lh-19 text-white fw-600'>É imprescindível esse documento para acesso às Salas VIP.</p>
            </div>
          </Container>
        )
      }
      {FormSuccess === 'form2' && (
        <Container className='d-flex align-items-center px-0'>
          <div className='col-12 px-0 mt-5'>
            <div className='d-flex justify-content-center'>
              <Circle>
                <CheckCircle height={32} width={32} color='#ffffff' />
              </Circle>
            </div>
            <h2 className='fs-24 lh-30 text-white fw-600 text-center mb-5'>Agradecemos pela resposta!</h2>
            <p className='fs-16 lh-19 text-white fw-400 text-center mb-4'>
              Este formulário é único e válido por <strong>um acesso</strong>. Para o acesso de dependentes, acompanhantes, cartão adicional e virtual, preencha o <strong>novo formulário</strong> que será enviado para o seu e-mail cadastrado.
            </p>
          </div>
        </Container>
        )
      }
      {FormSuccess === 'error' && (
        <Container className='d-flex align-items-center px-0'>
          <div className='col-12 px-0 text-center text-md-left'>
            <div className='d-md-flex px-0 my-4'>
              <div className='mr-2 mb-3 mb-md-0'>
                <OrangeDS size='LG' color='#ff7a00' icon='info' />
              </div>
              <h2 className='fs-24 lh-30 text-white fw-600 font-sora'>
                <span className='d-md-block'>Sua pesquisa não pode</span> ser enviada.
              </h2>
            </div>
            <p className='fs-16 lh-19 text-white ml-md-3 pl-md-4'>
              Por favor, tente novamente!
            </p>
          </div>
        </Container>
        )
      }
    </>
  )
}

export default Success
