import React from 'react'

import './ProgressBar.scss'

// translate
import textEN from 'src/pages/seguranca/assets/data/translate/perguntas/textEN.json'
import textPT from 'src/pages/seguranca/assets/data/translate/perguntas/textPT.json'
import { ContentType } from 'src/pages/seguranca/assets/data/translate/perguntas/types'

type progressBarProps = {
  progress: number;
  counter?: number;
  styles?: string;
  steps?: number;
  lang: string;
}

const ProgressBar = ({ progress, counter, steps, styles, lang }: progressBarProps) => {
  const { complete }: ContentType = lang === 'pt' ? textPT : textEN

  return (
    <div className='progress-bar'>
      <div className='progressWrapper'>
        {counter && steps && <p className={styles}>{Math.round(progress)}% {complete}</p>}
        {counter && steps && <p className={styles}>{counter}/{steps}</p> }
      </div>
      <div className='bar-area' />
      <div className='bar' style={{ width: progress + '%' }} />
    </div>
  )
}

ProgressBar.defaultProps = {
  progress: 0,
}

export default ProgressBar
