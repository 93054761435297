import styled, { css } from 'styled-components'

type ButtonCheckProps = {
  open?: boolean;
}

type CollapseProps = {
  open?: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 17px 12px 25px;
  border: 1px solid #F5F6FA;
  border-radius: 8px;
  background: #ffffff;

  & + div {
    margin-top: 16px;
  }
`
export const ButtonCheck = styled.button`
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  & + button {
    margin-top: 12px;
  }

  .arrow {
    transition: all 0.2s ease-in-out;

    path {
      stroke-width: 1.5;
    }

    ${(props: ButtonCheckProps) => props.open && css`
      transform: rotate(180deg);
    `}
  }

  &:focus {
    outline: none
  }
`

export const Collapse = styled.div`
  overflow: hidden;
  max-height: 0;

  @keyframes heigth {
    from {
      max-height: 0;
    }

    to {
      max-height: 100%;
    }
  }
  

  ${(props: CollapseProps) => props.open && css`
    animation: heigth 1s linear;
  `}
`
