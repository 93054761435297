import React, { useEffect, useState, useCallback } from 'react'
import { GoogleReCaptchaProvider, useGoogleReCaptcha, IGoogleReCaptchaConsumerProps } from 'react-google-recaptcha-v3'

import ProgressBar from 'src/components/ProgressBar'

import { Radio, Advanced, Return, Content, Input } from '../../style'
interface IQuestion {
  pergunta: string;
  resposta: string | null;
  id?: number | null;
}

type questionsProps = {
  questions: {
    pergunta: string;
    resposta: string;
  }[];
  questionData: string;
  subTitleQuestion?: string;
  answersData: IAnswers[];
  setQuestions: Function;
  setStep: Function;
  nextStep?: string;
  backStep: string;
  step: string;
  progress: number;
  setProgress: Function;
  sendForm?: boolean;
  setIsToken?: Function;
  headerText?: string;
  setBack?: Function;
  progressValue: number;
}

interface IAnswers {
  answer: string;
}

type TokenProps = {
  setIsToken?: Function;
  callback: Function;
  question: IQuestion;
  text: string;
}

const YourReCaptchaComponent = ({ setIsToken, callback, question, text }: TokenProps) => {
  const { executeRecaptcha }: IGoogleReCaptchaConsumerProps = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha('login_page')

    if (setIsToken) {
      setIsToken(token)
    }

    callback()
  }, [ executeRecaptcha ])

  return (
    <Advanced disabled={question.id === null || (question.resposta === '' && text === '') } onClick={handleReCaptchaVerify}>
      Enviar formulário
    </Advanced>
  )
}

const Question1 = ({
  questions,
  setQuestions,
  questionData,
  subTitleQuestion,
  answersData,
  setStep,
  nextStep,
  backStep,
  step,
  progress,
  setProgress,
  sendForm,
  setIsToken,
  headerText,
  setBack,
  progressValue,
}: questionsProps) => {
  const [ text, setText ] = useState('')
  const [ question, setQuestion ] = useState<IQuestion>({
    pergunta: questionData,
    resposta: null,
    id: null,
  })

  const handleClick = () => {
    if (text !== '') {
      delete question.id

      setQuestions([ ...questions, { ...question, resposta: text } ])
      setStep(nextStep || question.resposta)
    } else {
      delete question.id

      setQuestions([ ...questions, question ])
      setStep(nextStep || question.resposta)
    }
  }

  useEffect(() => {
    setQuestion({ pergunta: questionData, resposta: null, id: null })
    setProgress(progress)
  }, [ step ])

  const handleSelect = (answer: string, index: number) => {
      setQuestion({ ...question, resposta: answer, id: index })
  }

  const handleReturn = () => {
    if (setBack) {
      setBack(backStep)
    } else {
      setStep(backStep)
    }
  }

  return (
    <Content>
      <div>
        {!!headerText && <h2 className='fs-16 lh-20 text-white mb-3'>Sobre sua viagem</h2>}
        <h2 className='fs-16 lh-20 text-white fw-600 py-2'>
          {questionData}
        </h2>
        <p className='fs-12 lh-15 text-white mb-2'>{subTitleQuestion}</p>
        <ProgressBar progress={progress} styles='text-white mb-4' />
        {
        answersData.map((item: IAnswers, index: number) => (
          <div className='d-flex align-items-center justify-content-between' key={index}>
            <Radio
              active={question.id === index}
              onClick={() => handleSelect(item.answer, index)}
              className={`${item.answer === 'Outro' && 'mb-0'}`}
            >
              {item.answer}
            </Radio>
            {
              item.answer === 'Outro' && question.resposta === 'Outro' && (
                <Input
                  type='text'
                  placeholder='Outro'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setText(event.target.value)}
                  width={178}
                />
              )
            }
          </div>
        ))
      }
      </div>
      <div className='row d-flex align-items-center mt-4'>
        <div className='col-6'>
          <Return onClick={() => handleReturn()}>
            Voltar
          </Return>
        </div>
        <div className='col-6'>
          {
          sendForm ? (
            <GoogleReCaptchaProvider
              reCaptchaKey={`${process.env.RECAPTCHA_SITE_KEY}`}
            >
              <YourReCaptchaComponent
                setIsToken={setIsToken}
                callback={handleClick}
                question={question}
                text={text}
              />
            </GoogleReCaptchaProvider>

          ) : (
            <Advanced disabled={question.id === null || (question.resposta === '' && text === '') } onClick={() => handleClick()}>
              Avançar
            </Advanced>
          )
        }
        </div>
      </div>
    </Content>
  )
}

export default Question1
