import styled, { keyframes, css } from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { white, grayscale } from 'src/styles/colors'

type ContainerProps = {
  isModal?: () => void;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  &.modal-vip {
    background-color: ${white};
    border-radius: 16px;
    overflow-y: auto;
    padding: 24px;
    height: 70vh;

    @media (min-width: ${breakpoints.md}) {
      max-width: 596px;
      margin: 0 auto;
    }

    @media (min-width: ${breakpoints.xl}) {
      height: 80vh;
    }

    @media (min-width: ${breakpoints.xxxl}) {
      height: 60vh;
    }

    ul {
      padding-left: 16px;
      font-size: 12px;
      line-height: 15px;

      @media (min-width: ${breakpoints.md}) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 100vh;
      padding: 24px;;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 40px 100px;
      }
    `
  )}
`
export const Section = styled.section`
  min-height: 100vh;
  background: ${grayscale[500]};
`
