import styled from 'styled-components'
import { grayscale, orange, red, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 0 8px 24px 8px;
  box-shadow: 0 4px 8px -2px rgba(22, 22, 22, 0.08);
  border-radius: 16px;

  .error {
    input {
      color: ${red[500]};
      background: #FEF0ED;

      &::placeholder {
        color:  ${red[500]};
      }
    }
  }
`

export const Input = styled.input`
  width: 100%;
  height: 48px;
  background: ${grayscale[100]};
  border-radius: 8px;
  padding: 0 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};
  border: none;

  &::placeholder {
    color: ${grayscale[300]};
  }

  &:focus {
    outline: none;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 8px;
  background: ${orange.extra};
  color: ${white};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  @media (min-width: ${breakpoints.md}) {
    max-width: 100%;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: ${grayscale[200]};
    color: ${grayscale[300]};
  }
`

export const UpoladFile = styled.div`
  width: 100%;
  height: 64px;
  background: ${white};
  border: 1px solid ${grayscale[200]};
  border-radius: 8px;
  padding: 0 16px;
  display: flex;
  align-items: center;

  p, div, label {
    margin-bottom: 0;
  }

  image {
    display: none;
  }

  label {
    width: 100%;
    cursor: pointer;
  }

  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${orange.extra};
    padding-right: 5px;
  }

  .description {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
`

export const ButtonDelete = styled.button`
  background: none;
  padding: 0;
  border: none;

  &:focus {
    outline: none;
  }
`
