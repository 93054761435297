import React, { useState, useEffect, ChangeEvent, MouseEvent, useCallback } from 'react'
import { GoogleReCaptchaProvider, useGoogleReCaptcha, IGoogleReCaptchaConsumerProps } from 'react-google-recaptcha-v3'
import { useForm, UseFormMethods } from 'react-hook-form'
import axios from 'axios'

import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLs from 'src/config/api/Urls'
import AcceptTerms from 'src/components/AcceptTerms'
import NewsLetters from 'src/components/AcceptTerms/NewsLetters'
import ProgressBar from 'src/components/ProgressBar'

import SuccessMsg from '../success'

import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import errorImg from 'src/assets/images/shared/erro-pig.png'

import { Container, Input, Button, UpoladFile, ButtonDelete } from './style'
interface IFormValues {
  email: string;
  cpf: string;
  arquivo: string;
}

function blobToBase64 (blob: Blob | null) {
  // eslint-disable-next-line promise/param-names, @typescript-eslint/typedef, @typescript-eslint/no-unused-vars
  return new Promise((resolve, _) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    blob && reader.readAsDataURL(blob)
  })
}

function removeContentType (file: string) {
   return file.split(',')[1]
}

interface IForm {
  setFormData: Function;
  setStep: Function;
  nextStep: string;
  acesso: number;
  id: string;
  progress: number;
  setProgress: Function;
  progressValue: number;
}

type TokenProps = {
  setIsToken: Function;
  accept: boolean;
  loading: boolean;
  file: File | null;
  acceptSearch: boolean;
  acesso: number;
}

const YourReCaptchaComponent = ({ setIsToken, accept, loading, file, acceptSearch, acesso }: TokenProps) => {
  const { executeRecaptcha }: IGoogleReCaptchaConsumerProps = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha('login_page')
    setIsToken(token)
  }, [ executeRecaptcha ])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [ handleReCaptchaVerify ])

  return (
    <>
      {
        !file ? (
          <Button
            onClick={handleReCaptchaVerify}
            disabled={!accept || loading || !file || !acceptSearch}
            className='btn fs-14 fw-600 rounded-2 text-white text-none'
          >
            {acesso <= 4 ? 'Continuar' : 'Anexar'}
          </Button>
        ) : (
          <Button
            onClick={handleReCaptchaVerify}
            disabled={!accept || loading || !file || !acceptSearch}
            className='btn fs-14 fw-600 rounded-2 text-white text-none'
          >
            {acesso <= 4 ? 'Continuar' : 'Enviar'}
          </Button>
        )
      }
    </>
  )
}

function Form ({ setFormData, setStep, nextStep, acesso, id, progress, setProgress, progressValue }: IForm) {
  const [ isToken, setIsToken ] = useState('')
  const [ accept, setAccept ] = useState(false)
  const [ acceptNews, setAcceptNews ] = useState(false)
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ success, setSuccess ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ file, setFile ] = useState<File | null>(null)
  const [ fileError, setFileError ] = useState(false)
  const [ acceptSearch, setAcceptSearch ] = useState(false)

  const formSubmit = async (data: IFormValues) => {
    setLoading(true)
    const fileEncoded = await blobToBase64(file) as string

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      arquivo: removeContentType(fileEncoded),
      acesso,
      id,
      token: isToken,
      login_page: 'login_page',
    }

    if (acesso <= 4) {
      const dataForm = {
        ...formData,
        cpf: data.cpf.replace(/\D/g, ''),
        token: isToken,
        login_page: 'login_page',

      }

      setFormData(dataForm)
      setProgress(progress)
      setStep(nextStep)
      return
    }

    try {
      await axios.post(`${URLs.PATH_SIDLE}`, formData)
      setSuccess(true)
      setLoading(false)

      const userData = {
        id,
        acesso,
      }

      const storage = window.localStorage.getItem('accessControl')

      if (storage) {
        const storageDecript = window.atob(storage)
        const data = JSON.parse(storageDecript)

        data.push(userData)

        const userDataEncript = window.btoa(JSON.stringify(data))
        window.localStorage.setItem('accessControl', userDataEncript)
      } else {
        const userDataEncript = window.btoa(JSON.stringify([ userData ]))
        window.localStorage.setItem('accessControl', userDataEncript)
      }
    } catch {
      setLoading(false)
      setError(true)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setLoading(false)
  }

  const handleUpload = (file: File) => {
    if (file.size <= 5242880) {
      setFile(file)
      setFileError(false)
    } else {
      setFileError(true)
    }
  }

  return (
    <>
      { error && (
        <Container className='d-flex align-items-center'>
          <div className='col-12 text-center pt-4'>
            <h3 className='fs-20 lh-24 mb-5 text-white'>Ah não! Ocorreu um erro com a sua solicitação...</h3>
            <img src={errorImg} alt='Imagem de erro' className='pr-4' />
            <p className='fs-18 lh-22 pt-4 text-white'>
              Verifique a sua conexão com a internet e tente novamente. <br />
              <button onClick={handleReturn} title='Clique aqui para voltar' className='text-white bg-transparent border-0 fw-600'>
                Clique aqui
              </button>
              para voltar.
            </p>
          </div>
        </Container>
      )}
      { success &&
        <SuccessMsg FormSuccess='form1' />
      }
      <>
        <h2 className={`${error || success ? 'd-none' : 'd-flex'} fs-16 lh-20 text-white fw-600 py-2`}>
          Informações pessoais
        </h2>
        <p className={`${error || success ? 'd-none' : 'd-flex'} fs-12 lh-15 text-white mb-2`}>
          Para começar, precisamos confirmar alguns dados com você.
        </p>
        <ProgressBar progress={progressValue} styles='text-white mb-4' />
        <Container className={`${error || success ? 'd-none' : 'd-flex'} align-items-center pl-md-3`}>
          <form onSubmit={handleSubmit(formSubmit)}>
            <div className='row'>
              <div className={`col-12 mb-4 px-0 ${errors.cpf && 'error'}`}>
                <label className='fs-14 lh-17 fw-700 text-white d-block' htmlFor='cpf'>1. Qual é o seu CPF?</label>
                <Input
                  ref={register({
                  required: 'Insira um CPF válido',
                  validate: {
                    isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                  },
                })}
                  type='text'
                  id='cpf'
                  name='cpf'
                  placeholder='Insira apenas números'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                />
                {errors.cpf && <p className='fs-12 text-red--base mb-0 text-right'>{errors.cpf.message}</p>}
              </div>
              <div className={`col-12 mb-4 px-0 ${errors.email && 'error'}`}>
                <label className='fs-14 lh-17 fw-700 text-white d-block' htmlFor='email'>2. Qual o e-mail cadastrado na sua conta Inter?</label>
                <Input
                  ref={register({
                  required: 'Digite um e-mail válido',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'E-mail inválido',
                  },
                })}
                  type='text'
                  name='email'
                  id='email'
                  placeholder='Insira seu e-mail'
                />
                {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
              </div>
              <div className={`col-12 mb-3 px-0 ${errors.cpf && 'error'}`}>
                <label className='fs-14 lh-17 text-white d-block fw-700' htmlFor='file'>3. Insira a foto do seu cartão de embarque</label>
                <p className='fs-12 lh-15 text-white mb-4'>Para utilizar as Salas VIP em aeroportos, você precisa apresentar o seu cartão de embarque.</p>
                <UpoladFile>
                  {
                    file ? (
                      <div className='d-flex align-items-center justify-content-between w-100'>
                        <div>
                          <p className='description'>Arquivo anexado</p>
                          <p className='title'>
                            <strong>
                              {file.name}
                            </strong>
                          </p>
                        </div>
                        <div className='d-flex align-items-center'>
                          <label htmlFor='upload'>
                            <OrangeIcon size='MD' color='#ff7a00' icon='edit' className='mr-2' />
                          </label>
                          <ButtonDelete
                            type='button'
                            onClick={() => setFile(null)}
                          >
                            <OrangeIcon size='MD' color='#ff7a00' icon='delete' />
                          </ButtonDelete>
                        </div>
                      </div>
                    ) : (
                      <label htmlFor='upload' className='d-flex align-items-center justify-content-between'>
                        <div>
                          <p className='title'>
                            <strong>
                              Adicionar arquivos
                            </strong> até 5MB
                          </p>
                          <p className='description'>Arquivos JPEG, PNG </p>
                        </div>

                        <div>
                          <OrangeIcon size='MD' color='#ff7a00' icon='attachment' />
                        </div>
                      </label>
                    )
                  }
                  <input
                    type='file'
                    accept='image/*'
                    id='upload'
                    className='d-none'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => event.target.files && handleUpload(event.target.files[0])}
                  />
                </UpoladFile>
                {fileError && <p className='fs-12 text-red--base mb-0 text-right'>O arquivo não pode ser maior que 5MB</p>}
              </div>
              <div className='col-12 mb-2 px-0'>
                <AcceptTerms
                  accept={accept}
                  setAccept={setAccept}
                  name='accept'
                  styles='fs-12 lh-15 text-grayscale--300'
                  label='Autorizo o uso dos meus dados para acessar as Salas VIP e concordo com os Termos de Uso e'
                />
              </div>
              <div className='col-12 mb-2 px-0'>
                <NewsLetters
                  accept={acceptNews}
                  setAccept={setAcceptNews}
                  name='acceptNews'
                  styles='fs-12 lh-15 text-grayscale--300'
                  label='Autorizo o Inter a me enviar informações sobre marketing, publicidade, produtos e serviços.'
                />
              </div>
              <div className='col-12 mb-2 px-0'>
                <NewsLetters
                  accept={acceptSearch}
                  setAccept={setAcceptSearch}
                  name='acceptSearch'
                  styles='fs-12 lh-15 text-grayscale--300'
                  label='Concordo em responder a pesquisa para auxiliar o Inter a promover melhorias nos serviços.'
                />
              </div>
              {
              !success && (
                <div className='col-12 px-0'>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={`${process.env.RECAPTCHA_SITE_KEY}`}
                  >
                    <YourReCaptchaComponent
                      setIsToken={setIsToken}
                      loading={loading}
                      accept={accept}
                      file={file}
                      acceptSearch={acceptSearch}
                      acesso={acesso}
                    />
                  </GoogleReCaptchaProvider>
                </div>
                )
              }
            </div>
          </form>
        </Container>
      </>
    </>
  )
}

export default Form
