import React from 'react'

import ChevronDown from '@interco/icons/build-v4/orangeds/MD/chevron-down'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Container, ButtonCheck, Collapse } from './style'
import { orange } from 'src/styles/colors'

export type boxSelectionProps = {
  title: string;
  text: string;
  open: boolean;
  checked: boolean;
  setChecked: Function;
  setOpen: Function;
}

const BoxSelection = ({ title, text, open, checked, setChecked, setOpen }: boxSelectionProps) => {
  return (
    <Container>
      <ButtonCheck open={open} onClick={() => setOpen()}>
        <span className='fs-14 lh-17 text-grayscale--500 fw-700 d-block'>{title}</span>

        <ChevronDown height={32} width={32} color='#161616' className='arrow' />
      </ButtonCheck>

      <Collapse open={open}>
        <p className='fs-12 lh-15 text-grayscale--500 fw-400' dangerouslySetInnerHTML={{ __html: text }} />
      </Collapse>

      <ButtonCheck onClick={() => setChecked()}>
        <div className='mr-2'>
          {checked ? <OrangeIcon size='MD' color={orange.extra} icon='checkbox-on' /> : <OrangeIcon size='MD' color={orange.extra} icon='checkbox-off' />}
        </div>

        <p className='fs-12 lh-15 text-grayscale--400 fw-400 mb-0'>Estou ciente e de acordo com essas informações da Master Card</p>
      </ButtonCheck>
    </Container>
  )
}

export default BoxSelection
