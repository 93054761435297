/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Loading from '../../../LoadingIndicator'
import * as URLs from 'src/config/api/Urls'

import dataJSON from './assets/data/data.json'

// steps
import StepForm from './_steps/form'
import RadioQuestion from './_steps/radio-question'
import Nacional from './nacional'
import Internacional from './internacional'
import Success from './_steps/success'
import Validation from './_steps/validations'

// types
interface IQuestions {
  pergunta: string;
  resposta: string;
}

interface IFormValues {
  email: string;
  cpfCnpj: string;
  arquivo: string;
}

function Form () {
  const [ step, setStep ] = useState('form2')
  const [ formData, setFormData ] = useState<IFormValues>()
  const [ questions, setQuestions ] = useState<IQuestions[]>([])
  const [ progress, setProgress ] = useState(0)
  const [ acesso, setAccesso ] = useState(0)
  const [ id, setId ] = useState('')
  const [ token, setToken ] = useState('')

  let body = null

  const handleSubmit = async () => {
    setStep('loading')

    const data = {
      ...formData,
      aceite: true,
      token,
      acesso: acesso === 0 ? window.atob(window.localStorage.getItem('dd93289ds89ha98') ?? '') : acesso,
      id: id === '' ? window.atob(window.localStorage.getItem('dd93289ds89u83d') ?? '') : id,
      questionario: questions,
    }

    try {
      await axios.post(
        `${URLs.PATH_SIDLE}`,
        data,
      )
      setStep('success')

      const userData = {
        id,
        acesso,
      }

      const storage = window.localStorage.getItem('accessControl')

      if (storage) {
        const storageDecript = window.atob(storage)
        const data = JSON.parse(storageDecript)

        data.push(userData)

        const userDataEncript = window.btoa(JSON.stringify(data))
        window.localStorage.setItem('accessControl', userDataEncript)
      } else {
        const userDataEncript = window.btoa(JSON.stringify([ userData ]))
        window.localStorage.setItem('accessControl', userDataEncript)
      }
    } catch {
      setStep('error')
    }
  }

  useEffect(() => {
    const location = window.location.href
    const encript = location.split('?')[1]
    if (encript && !encript.includes('acesso=')) {
      const param = window.atob(encript)

      const params = param.split('&')

      const acesso = params[0].split('=')[1]
      const id = params[1].split('=')[1]

      window.localStorage.removeItem('dd93289ds89ha98')
      window.localStorage.removeItem('dd93289ds89u83d')

      if (acesso && id) {
        setAccesso(Number(acesso))
        setId(id)

        window.localStorage.setItem('dd93289ds89ha98', window.btoa(acesso))
        window.localStorage.setItem('dd93289ds89u83d', window.btoa(id))
      }
    }
  }, [])

  switch (step) {
    case 'form2':
      body = (
        <StepForm
          setFormData={setFormData}
          acesso={acesso}
          id={id}
          setStep={setStep}
          nextStep='validate'
          progress={12.5}
          setProgress={setProgress}
          progressValue={progress}
        />
      )
      break
    case 'validate':
      body = (
        <Validation
          questions={questions}
          setQuestions={setQuestions}
          questionData='Precisamos validar alguns dados'
          setStep={setStep}
          nextStep='question1'
          backStep='form2'
          progress={25}
          setProgress={setProgress}
          step={step}
          subTitleQuestion='Agora precisamos que você nos confirme alguns dados referente a regras de utilização da Sala Vip pela Mastercard Brasil.'
          progressValue={progress}
        />
      )
      break
    case 'question1':
      body = (
        <RadioQuestion
          questions={questions}
          setQuestions={setQuestions}
          questionData='7. Qual o destino final da sua viagem?'
          answersData={dataJSON.question1}
          setStep={setStep}
          backStep='validate'
          progress={37.5}
          setProgress={setProgress}
          step={step}
          headerText='Sobre sua viagem'
          progressValue={progress}
        />
      )
      break
    case 'Nacional':
      body = (
        <Nacional
          questions={questions}
          setQuestions={setQuestions}
          setBack={setStep}
          setToken={setToken}
          setProgress={setProgress}
          progress={progress}
        />
      )
      break
    case 'Internacional':
      body = (
        <Internacional
          questions={questions}
          setQuestions={setQuestions}
          setBack={setStep}
          setToken={setToken}
          setProgress={setProgress}
          progress={progress}
        />
      )
      break
    case 'sendForm':
      return handleSubmit()
    case 'loading':
      body = (
        <Loading />
      )
      break
    case 'success':
      body = (
        <Success FormSuccess='form2' />
      )
      break
    case 'error':
      body = (
        <Success FormSuccess='error' />
      )
      break
  }

  return (
    <>
      {body}
    </>
  )
}

export default Form
