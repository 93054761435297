import React, { useState, useEffect } from 'react'

// data
import boxSelectionJSON from '../../assets/data/box-selection.json'

// component
import BoxSelection from './BoxSelection'
import ProgressBar from 'src/components/ProgressBar'

import { Advanced, Return, Content } from '../../style'

interface IQuestion {
  pergunta: string;
  resposta: string;
}

type questionsProps = {
  questions: {
    pergunta: string;
    resposta: string;
  }[];
  questionData: string;
  setQuestions: Function;
  setStep: Function;
  nextStep: string;
  backStep: string;
  step: string;
  progress: number;
  setProgress: Function;
  subTitleQuestion: string;
  progressValue: number;
}

export type boxSelectionProps = {
  title: string;
  text: string;
  open: boolean;
  checked: boolean;
}

const Validations = ({
    questions,
    setQuestions,
    questionData,
    setStep,
    nextStep,
    backStep,
    step,
    progress,
    setProgress,
    subTitleQuestion,
    progressValue,
  }: questionsProps) => {
  const [ data, setData ] = useState<boxSelectionProps[]>(boxSelectionJSON)

  const [ question, setQuestion ] = useState<IQuestion>({
    pergunta: questionData,
    resposta: '',
  })

  const handleClick = () => {
    setQuestions([ ...questions, question ])
    setQuestion({ pergunta: questionData, resposta: 'aceito' })

    setStep(nextStep)
  }

  useEffect(() => {
    setProgress(progress)
  }, [ step ])

  const handleCheck = (position: number) => {
    const newList = data.map((item: boxSelectionProps, index: number) => {
      if (index === position) {
        return {
          ...item,
          checked: !item.checked,
        }
      } else {
        return item
      }
    })

    setData(newList)
  }

  const handleOpen = (position: number) => {
    const newList = data.map((item: boxSelectionProps, index: number) => {
      if (index === position) {
        return {
          ...item,
          open: !item.open,
        }
      } else {
        return item
      }
    })

    setData(newList)
  }

  return (
    <Content>
      <div>
        <h2 className='fs-16 lh-20 text-white fw-600 mb-2'>
          {questionData}
        </h2>
        <p className='fs-12 lh-15 text-white mb-2'>{subTitleQuestion}</p>
        <ProgressBar progress={progressValue} styles='text-white mb-4' />

        {data.map((item: boxSelectionProps, index: number) => (
          <BoxSelection
            key={item.title}
            title={item.title}
            text={item.text}
            open={item.open}
            checked={item.checked}
            setChecked={() => handleCheck(index)}
            setOpen={() => handleOpen(index)}
          />
        ))}
      </div>
      <div className='row d-flex align-items-center buttons mt-5'>
        <div className='col-6'>
          <Return onClick={() => setStep(backStep)}>
            Voltar
          </Return>
        </div>
        <div className='col-6'>
          <Advanced disabled={!data[0].checked || !data[1].checked || !data[2].checked} onClick={() => handleClick()}>
            Continuar
          </Advanced>
        </div>
      </div>
    </Content>
  )
}

export default Validations
