import React, { useState } from 'react'

// data
import data from '../assets/data/internacional.json'

// steps
import Radio from '../_steps/radio-question'
import Rating from '../_steps/rating'
import TextQuestion from '../_steps/TextQuestion'

type quetionsProps = {
  pergunta: string;
  resposta: string;
}

type iternacionalProps = {
  setBack: Function;
  setToken: Function;
  setProgress: Function;
  progress: number;
  questions: quetionsProps[];
  setQuestions: Function;
}

const Internacional = ({ setBack, setToken, setProgress, progress, questions, setQuestions }: iternacionalProps) => {
  const [ step, setStep ] = useState('question8')

  let body = null

  switch (step) {
    case 'question8':
      body = (
        <Radio
          questions={questions}
          setQuestions={setQuestions}
          questionData='8. Você possui a conta internacional do Inter (Global Account)?'
          answersData={data.question8}
          setStep={setStep}
          nextStep='question9'
          backStep='form2'
          progress={50}
          setProgress={setProgress}
          step={step}
          progressValue={progress}
        />
      )
      break
    case 'question9':
      body = (
        <Radio
          questions={questions}
          setQuestions={setQuestions}
          questionData='9. Qual sala VIP você está agora?'
          answersData={data.question9}
          setStep={setStep}
          nextStep='question10'
          backStep='question8'
          progress={63}
          setProgress={setProgress}
          step={step}
          progressValue={progress}
        />
      )
      break
    case 'question10':
      body = (
        <Radio
          questions={questions}
          setQuestions={setQuestions}
          questionData='10. A viagem que você está fazendo agora é para:'
          answersData={data.question10}
          setStep={setStep}
          nextStep='question11'
          backStep='question9'
          progress={76}
          setProgress={setProgress}
          step={step}
          progressValue={progress}
        />
      )
      break
    case 'question11':
      body = (
        <Radio
          questions={questions}
          setQuestions={setQuestions}
          questionData='11. Você conhece o Serviço de Viagens do Inter?'
          answersData={data.question11}
          setStep={setStep}
          nextStep='question12'
          backStep='question10'
          progress={89}
          setProgress={setProgress}
          step={step}
          progressValue={progress}
        />
      )
      break
    case 'question12':
      body = (
        <Rating
          questions={questions}
          setQuestions={setQuestions}
          questionData='12. Como você avalia as suas últimas visitas a uma Sala VIP?'
          setStep={setStep}
          nextStep='question13'
          backStep='question11'
          progress={95}
          setProgress={setProgress}
          step={step}
          progressValue={progress}
        />
      )
      break
    case 'question13':
      body = (
        <TextQuestion
          questions={questions}
          setQuestions={setQuestions}
          questionData='13. Você tem algum comentário ou sugestão sobre sua experiência com a Sala VIP?'
          setStep={setStep}
          nextStep='sendForm'
          backStep='question12'
          progress={100}
          setProgress={setProgress}
          step={step}
          img='salaVipGlobalAccount'
          setToken={setToken}
          setBack={setBack}
          progressValue={progress}
        />
      )
      break
  }

  return (
    <>
      {body}
    </>
  )
}

export default Internacional
