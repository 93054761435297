import React, { useState, useEffect, ChangeEvent, useCallback } from 'react'
import { GoogleReCaptchaProvider, useGoogleReCaptcha, IGoogleReCaptchaConsumerProps } from 'react-google-recaptcha-v3'
import Img from 'gatsby-image'

import ProgressBar from 'src/components/ProgressBar'

import pageQuery from '../../pageQuery'

import { Advanced, Return, Content, Input } from '../../style'

interface IQuestion {
  pergunta: string;
  resposta: string;
}

type TokenProps = {
  setIsToken?: Function;
  callback: Function;
  question: IQuestion;
}

type questionsProps = {
  questions: {
    pergunta: string;
    resposta: string;
  }[];
  questionData: string;
  setQuestions: Function;
  setStep: Function;
  nextStep: string;
  backStep: string;
  step: string;
  progress: number;
  setProgress: Function;
  img: string;
  setToken: Function;
  setBack?: Function;
  progressValue: number;
}

const YourReCaptchaComponent = ({ setIsToken, callback, question }: TokenProps) => {
  const { executeRecaptcha }: IGoogleReCaptchaConsumerProps = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha('login_page')

    if (setIsToken) {
      setIsToken(token)
    }

    callback()
  }, [ executeRecaptcha ])

  return (
    <Advanced disabled={question.resposta === ''} onClick={handleReCaptchaVerify}>
      Enviar formulário
    </Advanced>
  )
}

const TextQuestion = ({
    questions,
    setQuestions,
    questionData,
    setStep,
    nextStep,
    backStep,
    step,
    progress,
    setProgress,
    img,
    setToken,
    setBack,
    progressValue,
  }: questionsProps) => {
  const [ question, setQuestion ] = useState<IQuestion>({
    pergunta: questionData,
    resposta: '',
  })

  const data = pageQuery()

  const handleClick = () => {
    setQuestions([ ...questions, question ])
    setQuestion({ pergunta: questionData, resposta: '' })

    if (setBack) {
      setBack(nextStep)
    } else {
      setStep(nextStep)
    }
  }

  useEffect(() => {
    setProgress(progress)
  }, [ step ])

  return (
    <Content>
      <div>
        <h2 className='fs-16 lh-20 text-white fw-600 mb-2'>
          {questionData}
        </h2>
        <ProgressBar progress={progressValue} styles='text-white mb-4' />
        <div className='mb-5'>
          <Input
            type='text'
            placeholder='Inserir comentário'
            onChange={(event: ChangeEvent<HTMLInputElement>) => setQuestion({ ...question, resposta: event.target.value })}
          />
        </div>

        <p className='fs-14 lh-17 text-white fw-700'>Oferta para você:</p>
        <Img fluid={data[img].fluid} />
      </div>
      <div className='row d-flex align-items-center buttons'>
        <div className='col-6'>
          <Return onClick={() => setStep(backStep)}>
            Voltar
          </Return>
        </div>
        <div className='col-6'>
          <GoogleReCaptchaProvider
            reCaptchaKey={`${process.env.RECAPTCHA_SITE_KEY}`}
          >
            <YourReCaptchaComponent
              setIsToken={setToken}
              callback={handleClick}
              question={question}
            />
          </GoogleReCaptchaProvider>
        </div>
      </div>
    </Content>
  )
}

export default TextQuestion
