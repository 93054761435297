import { grayscale, orange, white, black } from 'src/styles/colors'
import styled from 'styled-components'

type RadioProps = {
  active?: boolean;
}

type InputProps = {
  width?: number;
}

export const Radio = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${white};
  margin-bottom: 22px;
  text-align: left;
  &:focus {
    outline: none;
  }

  &::before, &::after {
    content: '';
    display: block;
  }

  &::before {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid ${(props: RadioProps) => props.active ? `${orange.extra}` : `${grayscale[200]}`};
    margin-right: 19px;
  }

  ${(props: RadioProps) => props.active && `
    &::after {
      width: 14px;
      min-width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #ff7a00;
      position: absolute;
      left: 5px;
    }
  `}

  & + button {
    margin-top: 24px;
  }
`

export const Advanced = styled.button`
  width: 100%;
  height: 32px;
  background: ${orange.extra};
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${white};
  border: none;

  &:disabled {
    background: ${grayscale[200]};
    color: ${grayscale[300]};
  }
`

export const Return = styled.button`
  width: 100%;
  height: 32px;
  background: ${white};
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${orange.extra};
  border: none;
`

export const CheckBox = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${white};
  margin-bottom: 22px;
  text-align: left;

  &:focus {
    outline: none;
  }

  &::before, &::after {
    content: '';
    display: block;
  }

  &::before {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid ${(props: RadioProps) => props.active ? `${orange.extra}` : `${grayscale[200]}`};
    margin-right: 19px;
    ${(props: RadioProps) => props.active && `
      background: #ff7a00;
    `}
  }

  ${(props: RadioProps) => props.active && `
    &::after {
      display: block;
      position: absolute;
      content: '';
      left: 1px;
      top: 50%;
      width: 7px;
      height: 12px;
      border: solid ${black};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) translateY(-94%);
    }
  `}

  & + button {
    margin-top: 24px;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

export const Input = styled.input<InputProps>`
  width: ${(props: InputProps) => props.width ? `${props.width}px` : '100%' };
  height: 37px;
  background: ${grayscale[100]};
  border-radius: 8px;
  padding: 0 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};
  border: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${grayscale[300]};
  }
`
